<template>
  <section id="about-us" class="grey lighten-3">
    <div class="py-6"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">ABOUT US</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive
        class="mx-auto title font-weight-light mb-8"
        max-width="720"
      >
        SR Clothing Studio has been established by some
        <strong>heavily experienced veterans</strong> in Apparel Merchandising &
        Production sector in Bangladesh. It is currently serving many clients
        from the <strong>EU, Canada, Australia & USA</strong> with pride. We are
        capable of sourcing and exporting of any kind of Apparel item like
        <strong
          >Streetwear, Outerwear, Sportswear, Kidswear, Homewear, Undergarments,
          etc</strong
        >. We have very good sourcing of different sizes Knitted, Woven and
        Sweater manufacturer.
        <strong
          >We can produce big quantities here and are able to meet any
          certification requirements</strong
        >. We also own a
        <strong
          >small factory capable of producing all kinds of knitwear and many
          woven items as well without any MOQ</strong
        >. We always try to provide
        <strong>cheaper sourcing services</strong> but maintaining the
        <strong>highest standard of quality</strong>.
      </v-responsive>
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style></style>
